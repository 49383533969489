import {CashierMandatoryFieldsOverrides} from '../../../types/checkoutApp.types';

export class PaymentService {
  public cashierMandatoryFields: CashierMandatoryFieldsOverrides = {};
  public expressPaymentMethodsAmount: number = 0;

  public setCashierMandatoryFields(cashierMandatoryFields: CashierMandatoryFieldsOverrides): void {
    this.cashierMandatoryFields = cashierMandatoryFields;
  }

  public setExpressPaymentMethodsAmount(numberOfButtons: number): void {
    this.expressPaymentMethodsAmount = numberOfButtons;
  }
}
